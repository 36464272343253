import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Banner from "../Shared/Banner";
import ScaledImage from "../UI/ScaledImage";

const PressReleasesBanner = () => {
  return (
    <Banner name="Press Releases" description="The Latest News" col={8}>
      <ScaledImage bg={true}>
        <StaticImage
          src="../../images/banner-press-releases.jpg"
          alt="banner"
          layout="fullWidth"
        />
      </ScaledImage>
    </Banner>
  );
};

export default PressReleasesBanner;
