import React from 'react'
import FeaturedArticleSlideCard from '../Cards/FeaturedArticleSlideCard'
import Carousel from '../UI/Carousel'
import Element from '../UI/Element'
import { Heading } from '../UI/Typography'

const PressReleaseSlider = ({ articles }) => {
  const featuredArticles = articles.filter(i => i.featured === true)

  const featuredArticlesSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Element mb={16}>
      <Heading mb={10} size="h2" textAlign="center">
        Latest News and Announcements
      </Heading>
      <Carousel settings={featuredArticlesSettings}>
        {featuredArticles.slice(0, 3).map(a => (
          <Element pb={6} key={a.id}>
            <FeaturedArticleSlideCard
              title={a.title}
              publishDate={a.published_date}
              excerpt={a.excerpt}
              thumbnail={a.photo_url}
              slug={a.slug}
            />
          </Element>
        ))}
      </Carousel>
    </Element>
  )
}

export default PressReleaseSlider
