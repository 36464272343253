import React from "react";
import Icon from "../UI/Icon";

const PaginatePrevIcon = () => {
  return (
    <Icon viewBox="0 0 18 18">
      <path d="M7.37857 4.28236C7.65482 4.57486 7.65482 5.04736 7.37857 5.33986L4.63023 8.24986H15.3757C15.7652 8.24986 16.084 8.58736 16.084 8.99986C16.084 9.41236 15.7652 9.74986 15.3757 9.74986H4.62315L7.37148 12.6599C7.64773 12.9524 7.64773 13.4249 7.37148 13.7174C7.09523 14.0099 6.64898 14.0099 6.37273 13.7174L2.41315 9.52486C2.1369 9.23236 2.1369 8.75986 2.41315 8.46736L6.37982 4.28236C6.64898 3.98986 7.10232 3.98986 7.37857 4.28236Z" />
    </Icon>
  );
};

export default PaginatePrevIcon;
