import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from '../UI/Grid'
import PressReleaseWrapper from './PressReleaseWrapper'
import dayjs from 'dayjs'
import styled, { css } from 'styled-components'
import theme from '../../utils/theme'
import Element from '../UI/Element'
import { Section } from '../UI/Section'
import PressReleaseSlider from './PressReleaseSlider'
import FacebookIcon from '../Icons/FacebookIcon'
import LinkedIcon from '../Icons/LinkedIcon'
import { FACEBOOK_URL, LINKEDIN_URL } from '../../utils/links'
import { Anchor } from '../UI/Typography'
import DefaultVector from '../Shared/DefaultVector'
import axios from 'axios'

const PressReleaseContent = () => {
  const isBrowser = typeof window !== 'undefined'
  const [processing, setProcessing] = useState(true)

  const [articles, setArticles] = useState([])
  const [years, setYears] = useState([])

  const [articleList, setArticleList] = useState([])

  const [activeYear, setActiveYear] = useState()

  useEffect(() => {
    axios
      .get(
        `${
          isBrowser && window?.location?.origin === 'https://fastech.digiteer.dev'
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/news`,
      )
      .then(res => {
        setArticles(res.data)
        setArticleList(res.data)
      })
      .finally(() => setProcessing(false))
  }, [])

  useEffect(() => {
    setYears(
      articles
        .map(i => dayjs(i.published_date).year())
        .filter((value, index, self) => self.indexOf(value) === index),
    )
  }, [articles])

  const handleFilter = year => {
    const filteredArticle = articles.filter(i => `${dayjs(i.published_date).year()}` === year)
    setArticleList(filteredArticle)
    setActiveYear(year)
  }

  // let years = []

  // years = articles
  //   .map(i => dayjs(i.frontmatter.published_date).year())
  //   .filter((value, index, self) => self.indexOf(value) === index)

  const yearCount = year => {
    const articlesCount = articles.filter(i => dayjs(i.published_date).year() === year)
    return articlesCount.length
  }

  if (processing) {
    return false
  }

  return (
    <Section py={{ _: 16, lg: 0 }}>
      <DefaultVector>
        <Container mb={{ _: 0, lg: 20 }}>
          <PressReleaseSlider articles={articles} />

          <Element size="h6" fontWeight="semibold" mb={6}>
            News Archive
          </Element>
          <Row rowGap={6}>
            <Col col={{ lg: 4 }}>
              {years.map((y, idx) => (
                <Year
                  onClick={() => handleFilter(`${y}`)}
                  key={idx}
                  active={activeYear === `${y}` && 'true'}
                >
                  {y} ({yearCount(y)})
                </Year>
              ))}

              <Element size="h6" fontWeight="semibold" mt={10} mb={2}>
                Connect With Us
              </Element>

              <Row gutterSize="2">
                <Col col="auto">
                  <Element
                    as={Anchor}
                    size="h4"
                    href={FACEBOOK_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </Element>
                </Col>
                <Col col="auto">
                  <Element
                    as={Anchor}
                    size="h4"
                    href={LINKEDIN_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedIcon />
                  </Element>
                </Col>
              </Row>
            </Col>
            <Col col={{ lg: 8 }}>
              <PressReleaseWrapper articlesPerPage={6} articles={articleList} filtered={true} />
            </Col>
          </Row>
        </Container>
      </DefaultVector>
    </Section>
  )
}

const Year = styled.button`
  display: flex;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  color: ${theme.colors.text};
  min-height: 60px;
  width: 100%;
  align-items: center;
  padding: 12px;

  &:focus {
    outline: none;
  }

  ${p =>
    p.active === 'true' &&
    css`
      color: ${theme.colors.primary};
      background: ${theme.colors.lilac};
    `}
`

export default PressReleaseContent
