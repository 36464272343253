import React from 'react'
import { Row, Col } from '../UI/Grid'
import NewsCard from '../Cards/NewsCard'

const PressReleaseList = ({ currentArticles }) => {
  return (
    <Row rowGap={8}>
      {currentArticles.map(a => (
        <Col col={{ lg: 6 }} key={a.id}>
          <NewsCard
            title={a.title}
            slug={a.slug}
            excerpt={a.excerpt}
            thumbnail={a.photo_url}
            date={a.published_date}
          />
        </Col>
      ))}
    </Row>
  )
}

export default PressReleaseList
