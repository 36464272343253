import dayjs from 'dayjs'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/theme'
import Button from '../UI/Button'
import { Card, CardContent } from '../UI/Card'
import { Row, Col } from '../UI/Grid'
import ScaledImage from '../UI/ScaledImage'
import { Heading, Paragraph } from '../UI/Typography'
import { Date } from './FeaturedArticle'

const FeaturedArticleSlideCard = ({ publishDate, title, excerpt, slug, thumbnail }) => {
  return (
    <CardStyled cardhidden rounded flat bg="gray">
      <Row gutterSize={0}>
        <Col col={{ lg: 4 }}>
          <ScaledImage scaleHeight={332} scaleWidth={437}>
            <img src={thumbnail} alt="news" />
          </ScaledImage>
        </Col>
        <Col col={{ lg: 7 }}>
          <CardContent py={{ _: 4, lg: 9 }} px={{ _: 4, lg: 5 }}>
            <Date mb={4}>{dayjs(publishDate).format('MMMM YYYY')}</Date>
            <Heading size="h4" mb={2} lineHeight={1.2}>
              {title}
            </Heading>
            <Paragraph mb={6}>{excerpt}</Paragraph>
            <Button as={Link} variant="brand" to={slug}>
              Read More
            </Button>
          </CardContent>
        </Col>
      </Row>
    </CardStyled>
  )
}

const CardStyled = styled(Card)`
  background: ${theme.colors.lightGray};

  ${Date} {
    color: ${theme.colors.brand};
  }
`

export default FeaturedArticleSlideCard
