import React, { useState, useEffect } from "react";
import PressReleaseList from "./PressReleaseList";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import PaginateNextIcon from "../Icons/PaginateNextIcon";
import PaginatePrevIcon from "../Icons/PaginatePrevIcon";
import theme from "../../utils/theme";
import Element from "../UI/Element";

const PressReleaseWrapper = ({ articlesPerPage, articles, filtered }) => {
  // We start with an empty list of articles.
  const [currentPage, setCurrentPage] = useState(0);
  const [currentArticles, setCurrentArticles] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [articleOffset, setArticleOffset] = useState(0);

  useEffect(() => {
    // Fetch articles from another resources.
    const endOffset = articleOffset + articlesPerPage;
    setCurrentArticles(articles.slice(articleOffset, endOffset));
    setPageCount(Math.ceil(articles.length / articlesPerPage));
  }, [articleOffset, articlesPerPage, articles]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log(`clicked ${event}`);
    setCurrentPage(event.selected);
    const newOffset = (event.selected * articlesPerPage) % articles.length;
    setArticleOffset(newOffset);
  };

  useEffect(() => {
    setArticleOffset(0);
    setCurrentPage(0);
  }, [articles]);

  return (
    <>
      <PressReleaseList currentArticles={currentArticles} />
      {articles.length > articlesPerPage && (
        <Element mt={10}>
          <Pagination
            breakLabel="..."
            nextLabel={<PaginateNextIcon />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            forcePage={currentPage}
            previousLabel={<PaginatePrevIcon />}
            renderOnZeroPageCount={undefined}
          />
        </Element>
      )}
    </>
  );
};

const Pagination = styled(ReactPaginate)`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  column-gap: 4px;

  li {
    &.selected a {
      color: ${theme.colors.white};
      background: ${theme.colors.brand};
    }
    &.disabled a {
      color: ${theme.colors.darkGray};
      background: none;
      pointer-events: none;
    }
  }

  a {
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors.brand};
    background: ${theme.colors.lilac};
    cursor: pointer;
  }
`;

export default PressReleaseWrapper;
