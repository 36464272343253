import React from 'react'
import Seo from '../../components/Layout/Seo'
import Site from '../../components/Layout/Site'
import PressReleaseContent from '../../components/PressReleases/PressReleaseContent'
import PressReleasesBanner from '../../components/PressReleases/PressReleasesBanner'

const PressReleases = () => {
  return (
    <Site>
      <Seo title="Press Releases" />
      <PressReleasesBanner />
      <PressReleaseContent />
    </Site>
  )
}

export default PressReleases
