import React from "react";
import Icon from "../UI/Icon";

const PaginateNextIcon = () => {
  return (
    <Icon viewBox="0 0 18 18">
      <path d="M10.6214 4.28236C10.3452 4.57486 10.3452 5.04736 10.6214 5.33986L13.3698 8.24986H2.62435C2.23477 8.24986 1.91602 8.58736 1.91602 8.99986C1.91602 9.41236 2.23477 9.74986 2.62435 9.74986H13.3768L10.6285 12.6599C10.3523 12.9524 10.3523 13.4249 10.6285 13.7174C10.9048 14.0099 11.351 14.0099 11.6273 13.7174L15.5868 9.52486C15.8631 9.23236 15.8631 8.75986 15.5868 8.46736L11.6202 4.28236C11.351 3.98986 10.8977 3.98986 10.6214 4.28236Z" />
    </Icon>
  );
};

export default PaginateNextIcon;
